import axios from "axios";
import { ResponseUrl } from '../models/authUser';
import { ChannelWebCashRegister, Store } from '../models/orders';
import { CONFIG } from './configuracion.service';

const url = CONFIG.url + CONFIG.versionApi;

// export const getProductos = async (id: string) => {
//   try {
//     const response = await fetch(`${url}/stores/${id}`);
//     const data = await response.json().then(result => result as ResponseUrl<Store>);
//     // console.log(data);
//     return data;
//   } catch (error) {
//     console.log(error);
//   }
// }

export const getProductos = async (id: string) => {  
  try {
    const response = await axios.get<ResponseUrl<Store>>(`${url}/qiosco/menu/${id}`);    
    return response;
  } catch (error: any) {
    console.log('getProductos', error.message);
  }
};

export const getChannel = async (id: string) => {  
  try {
    const response = await axios.get<ResponseUrl<ChannelWebCashRegister[]>>(`${url}/qiosco/canalventa/${id}`);    
    return response;
  } catch (error: any) {
    console.log('getChannels', error.message);
  }
};