import axios from "axios"
import { LoginObject, SessionAuth } from '../models/authUser';
import { CONFIG } from './configuracion.service';

const url = CONFIG.url + CONFIG.versionApi ;

export const Login = async (loginObj:LoginObject) => {
    try{       
        const response = await axios.post<SessionAuth>(`${url}/authentication/createtoken`, JSON.stringify(loginObj), 
            {headers: {
                'Content-Type': 'application/json',
            },       
        });       
        return response;
    } catch (error: any) {
        const response = error.response;    
       
        return response;
    } 
}

export const LoginGoogle = async (loginObj:any) => {
    try{       
        const response = await axios.post<SessionAuth>(`${url}/authentication/login-google`, JSON.stringify(loginObj), 
            {headers: {
                'Content-Type': 'application/json',
            },       
        });       
        return response;
    } catch (error: any) {
        console.log('LoginGoogle', error.message);
       
    } 
}
