import { Provider } from "react-redux";
import "./App.css";
import Router from "./app/router";
import { UserProvider } from "./app/context/UserProvider";

const App = () => {
  return <Router />
};

export default App;
