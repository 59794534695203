import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Typography, keyframes } from "@mui/material";
import { themes } from "../../../config/Icons";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import { ChannelWebCashRegister } from "../../../models/orders";

interface Props {
  isDarkMode: boolean;
  setDirection: (direction: string | null) => void;
  ThemesColors: any;
}

const SelectionObsionSection = ({ isDarkMode, setDirection, ThemesColors }: Props) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const { idRuta } = useContext(UserContext);  
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string>(localStorage.getItem("userName") || "");
  const [options, setOptions] = useState<ChannelWebCashRegister[]>(JSON.parse(localStorage.getItem("options") || "[]"));

  //agregar el stora para pasar el name
  useEffect(() => {    
    idRuta(location.pathname);
    const storedName = localStorage.getItem('userName');
    if (storedName) {
      setUserName(storedName);
    }
  }, []);

  const handleLeftButtonClick = () => {
      navigate('/nameform');
      setDirection("backward");
  };

  const handleNavigation = (channelSelected: ChannelWebCashRegister) => {
   
    localStorage.setItem('channelSelected', JSON.stringify(channelSelected));
    // Si hay lógica adicional para establecer el nombre de usuario
    const storedName = localStorage.getItem('userName');
    if (storedName) {
      setUserName(storedName);
    } else {
      // Manejar el caso en que no haya nombre de usuario almacenado
    }
    navigate("/listapedido", { state: { userName } });
  };


  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        maxHeight: "100vh",
        padding: "var(--radio-md, 20px) 23px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "60px",
        background: "#FFF",
      }}
    >
      <Typography
        sx={{
          color: "var(--texto, #1E2947)",
          fontFamily: "Poppins",
          fontSize: "36px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "120%",
        }}
      >
        ¿Dónde deseas comer?
      </Typography>

      {/* CARDS */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "28px"          
        }}
      >
        {/* {options.map((option: any, index: any) => ( */}
        <Grid
          onClick={handleNavigation.bind(null,options[0])}
          sx={{
            display: "flex",
            width: "280px",
            height: "240px",
            padding: "40px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "28px",
            borderRadius: "var(--radio-sm, 12px)",
            boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
            ":hover": {
              borderRadius: "12px",
              boxShadow: "0px 4px 16px 0px rgba(2, 41, 71, 0.32)",
            },
            cursor: "pointer",
          }}
        >
            <img
            //src={themeMode.icons.tiendaHover}
            style={{
              width: "100px",
              height: "100px",
              WebkitMaskImage: `url(${themeMode.icons.tiendaHover})`,
              WebkitMaskRepeat: "no-repeat",
              WebkitMaskSize: "100px",
              backgroundColor: ThemesColors.activo,
            }}
          />
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Gotham",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "120%",
              alignSelf: "stretch",
            }}
          >
            {options.length > 0 ? options[0].nameChannel : 'En el local'}
          </Typography>
        </Grid>
        {/* ))
        } */}
        <Grid
          onClick={handleNavigation.bind(null,options[1])}
          sx={{
            display: "flex",
            width: "280px",
            height: "240px",
            padding: "40px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "28px",
            borderRadius: "var(--radio-sm, 12px)",
            boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
            ":hover": {
              borderRadius: "12px",
              boxShadow: "0px 4px 16px 0px rgba(2, 41, 71, 0.32)",
            },
            cursor: "pointer",
          }}
        >
         <img
            //src={themeMode.icons.bolsaHover}
            style={{
              width: "100px",
              height: "100px",
              WebkitMaskImage: `url(${themeMode.icons.bolsaHover})`,
              WebkitMaskRepeat: "no-repeat",
              WebkitMaskSize: "100px",
              backgroundColor: ThemesColors.activo,
            }}
          />
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Gotham",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "120%",
              alignSelf: "stretch",
            }}
          >
            {options.length > 0 ? options[1].nameChannel : 'Para llevar'}
          </Typography>
        </Grid>
      </Box>

      {/* BOTON ICONO IZQUIERDA */}
      <Box
        onClick={handleLeftButtonClick}
        sx={{
          display: "flex",
          width: "60px",
          height: "60px",
          padding: "15px",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "20px",
          top: "20px",
          borderRadius: "24px",
          //background: "var(--color-full-800-primario, #42C67E)",
          background: ThemesColors.activo,
          boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
          ":hover": {
            borderRadius: "24px",
           // background: "var(--colorSecondary-Green)",
           background: ThemesColors.activo,
            boxShadow: "3px 2px 10px 3px rgba(30, 41, 71, 0.14)",
          },
        }}
      >
        <img
          src={themeMode.icons.flechaIzquierdaPasiva}
          style={{
            width: "30px",
            height: "30px",
            flexShrink: 0,
          }}
        />
      </Box>
    </Box>
  );
};

export default SelectionObsionSection;
