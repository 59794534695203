var set = require('lodash/set');
var tinycolor = require("tinycolor2");

interface LottieJSON {
  [key: string]: any;
}

interface ColorByPath {
  [key: string]: string | null | undefined;
}

function colorizeLottie(json: LottieJSON, colorByPath: ColorByPath): LottieJSON {
    const nextJson: LottieJSON = JSON.parse(JSON.stringify(json));
  
    Object.entries(colorByPath).forEach(([path, color]) => {
      if (!color) return;
  
      // Convert color to RGB fraction
      const rgb = tinycolor(color).toRgb();
      const rFraction = rgb.r / 255;
      const gFraction = rgb.g / 255;
      const bFraction = rgb.b / 255;
  
      // Split the path and set the color
      const pathParts = path.split('.');
      set(nextJson, [...pathParts, 0], rFraction);
      set(nextJson, [...pathParts, 1], gFraction);
      set(nextJson, [...pathParts, 2], bFraction);
    });
  
    return nextJson;
  }

export default colorizeLottie;
