export function RGBAColor(color: string | undefined, opacity: number = 0.09): string {
    if (!color) {
      return `rgba(0, 0, 0, ${opacity})`; // Si el color es undefined, se retorna negro con opacidad.
    }
  
    let r: number, g: number, b: number;
  
    if (color.startsWith("#")) {
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    } else if (color.startsWith("rgb")) {
      const rgb = color.match(/(\d+),\s*(\d+),\s*(\d+)/);
      if (rgb) {
        r = parseInt(rgb[1], 10);
        g = parseInt(rgb[2], 10);
        b = parseInt(rgb[3], 10);
      } else {
        return `rgba(0, 0, 0, ${opacity})`;
      }
    } else {
      return `rgba(0, 0, 0, ${opacity})`;
    }
  
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  