import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography, keyframes } from "@mui/material";
import { themes } from "../../../config/Icons";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import { getChannel, getProductos } from "../../../services/productos.service";
import { ChannelWebCashRegister, WebCashRegister } from "../../../models/orders";
import WelcomeLandingCustom from "../../../components/WelcomeLandingCustom";
import WelcomeLanding from "../../../components/WelcomeLanding";
import SkeletonWelcome from "../../../components/Skeleton/SkeletonWelcome";
import ModalCerrarSesion from "../../../components/ModalesPopupForm/ModalCerrarSesion";

interface Props {
  isDarkMode: boolean;
  ThemesColors:any;
}
const WelcomeSection = ({ isDarkMode, ThemesColors }: Props) => {
  //Modo OSCURO Y CLAR
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const {
    store,
    handleLocalStorage,
    ruta,
    idRuta,
    idTokenUser
  } = useContext(UserContext);
  const [webCashRegister, setWebCashRegister] = useState<WebCashRegister| null>(localStorage.getItem("webCashRegister") ? JSON.parse(localStorage.getItem("webCashRegister") || "") : null); 
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<ChannelWebCashRegister[]>([]);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const handleRightButtonClick = () => {
    navigate('/nameform'); // Redirige al usuario
  };

  const fetchInformationStore = async() =>{    
    const listProductos = await getProductos(store);
    
    if (listProductos) {  
      const listChannel = await getChannel(store);
      if(listChannel){
        handleLocalStorage(listProductos, store, listChannel);  
        setWebCashRegister(listProductos.data.data.web_cash_register);       
        if((listProductos.data.data.web_cash_register !== null || listProductos.data.data.web_cash_register !== undefined) && (listChannel !== null && listChannel.data.data.length > 0)){
          listChannel.data.data.forEach((lc, index) =>{
            if(listProductos.data.data.web_cash_register.isActiveChannel1 && lc.codeChannel === '01'){
              setOptions((prevOptions) => [
                ...prevOptions,
                lc,
              ]);          
            }

            if(listProductos.data.data.web_cash_register.isActiveChannel2 && lc.codeChannel == '02'){
              setOptions((prevOptions) => [
                ...prevOptions,
                lc,
              ]);                 
            }

            if(listProductos.data.data.web_cash_register.isActiveChannel3 && lc.codeChannel == '03'){
              setOptions((prevOptions) => [
                ...prevOptions,
                lc,
              ]);              
            }

            if(listProductos.data.data.web_cash_register.isActiveChannel4 && lc.codeChannel == '04'){
              setOptions((prevOptions) => [
                ...prevOptions,
                lc,
              ]);      
            }

            if(listProductos.data.data.web_cash_register.isActiveChannel5 && lc.codeChannel == '05'){
              setOptions((prevOptions) => [
                ...prevOptions,
                lc,
              ]);      
            }
          })
        }       
       
        setIsLoading(false);
      }
      else{
        idRuta(undefined);
        idTokenUser(undefined);
        return navigate("/");
      }
      
    }      
    else{
      idRuta(undefined);
      idTokenUser(undefined);
      return navigate("/");
    }
  }
  useEffect(() => {
    if(ruta == "/welcome"){      
      localStorage.removeItem("basket");
      localStorage.removeItem("basketOrder");
      localStorage.removeItem("order_id");
      localStorage.removeItem("basketModifier");
      localStorage.removeItem("propina");
      localStorage.removeItem("checkModifier");  
      localStorage.removeItem("basketModifierEditar");      
      localStorage.removeItem("boleta");    
      fetchInformationStore();
    }else{   
      localStorage.removeItem("basket");
      localStorage.removeItem("basketOrder");
      localStorage.removeItem("order_id");
      localStorage.removeItem("basketModifier");
      localStorage.removeItem("propina");
      localStorage.removeItem("checkModifier");  
      localStorage.removeItem("basketModifierEditar");      
      localStorage.removeItem("boleta");          
      setTimeout(() => setIsLoading(false), 400);
    };   
    
  }, []);

  useEffect(() => {
    if(ruta == "/welcome"){     
      localStorage.setItem("options", JSON.stringify(options));
    } 
    
    
  }, [options]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //Cerrar Seccion
  const LoginCerrar = () => {
    // Incrementa el contador de clics
    setClickCount((prevCount) => {
      const newCount = prevCount + 1;
      // Verifica si ha llegado a 10 clics
      if (newCount === 10) {
        handleOpenModal(); // Abre el modal
        return 0; // Reinicia el contador a 0
      }
      return newCount;
    });
  };

  return (
    <>
      {isLoading ? (
        <SkeletonWelcome />
      ) : 
      webCashRegister?.isWelcomeCustomize ? (
        <>
          <Button            
            onClick={LoginCerrar}
            sx={{
              display: "flex",
              width: "100px",
              height: "100px",
              padding: "25px",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: "0px",
              left: "0px",
              //  background: "red",
            }}
          >
           
          </Button>

          <WelcomeLandingCustom
            isDarkMode={isDarkMode}
            ThemesColors={ThemesColors}
          />
          <ModalCerrarSesion
            open={openModal}
            handleClose={handleCloseModal}
            themeMode={themeMode}
            setIsLoadingScreen={setIsLoading}
          />
        </>
      ) : (
        <>
          <Button
            
            onClick={LoginCerrar}
            sx={{
              display: "flex",
              width: "100px",
              height: "100px",
              padding: "25px",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: "0px",
              left: "0px",
              //background: "red",
            }}
          >
            
          </Button>
          <WelcomeLanding isDarkMode={isDarkMode} ThemesColors={ThemesColors} />
          <ModalCerrarSesion
            open={openModal}
            handleClose={handleCloseModal}
            themeMode={themeMode}
            setIsLoadingScreen={setIsLoading}
          />
        </>
      )}
    </>
    
  );
};

export default WelcomeSection;
