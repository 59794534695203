import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box, Stack } from "@mui/material";

const SkeletonWelcome = () => {
  const repeatedContent = () => (
    <Box
    sx={{
      display: "flex",
      alignItems: "center",
      marginBottom: "12px", // Reducido el margen inferior
      gap: "24px", // Espacio entre el texto y el logo, ligeramente reducido
    }}
  >
    {/* Texto */}
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Coloca los textos en una columna
        alignItems: "flex-start", // Alinea el texto a la izquierda
        gap: "12px", // Espacio entre los elementos de texto, ligeramente reducido
        maxWidth: "100%", // Ajusta el ancho máximo del contenedor de texto
      }}
    >
      <Skeleton
        variant="text"
        sx={{
          borderRadius: "16px", // Reducido el radio del borde
          fontSize: "35px", // Tamaño de fuente ligeramente reducido
          width: "280px", // Ancho reducido para el primer texto
          marginBottom: "6px", // Espacio debajo del primer texto, ligeramente reducido
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "6px", // Espacio entre los textos secundarios, ligeramente reducido
          marginLeft: "12px", // Espacio a la izquierda del grupo de textos secundarios, ligeramente reducido
        }}
      >
         <Skeleton
          variant="text"
          sx={{ borderRadius: '12px', fontSize: '20px', width: '280px' }}
        />
        <Skeleton
          variant="text"
          sx={{ borderRadius: '12px', fontSize: '20px', width: '150px', marginTop: '8px' }}
        />
      </Box>
    </Box>

    {/* Logo */}
    <Skeleton
      variant="rectangular"
      width="130px" // Tamaño reducido para el logo
      height="130px"
      sx={{
        borderRadius: "10px", // Reducido el radio del borde
      }}
    />
  </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        minHeight: "90vh",
        maxHeight: "100vh",
        padding: "80px 274px",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/*Texto*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Centra horizontalmente
          justifyContent: "center", // Centra verticalmente
          textAlign: "center", // Opcional: centra el texto dentro de cada Skeleton
          width: "100%", // Asegura que el Box ocupe todo el ancho disponible
        }}
      >
        <Skeleton
          variant="text"
          sx={{
            borderRadius: "20px",
            width: "460px",
            fontSize: "50px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "120%",
          }}
        />
        <Skeleton
          variant="text"
          sx={{
            borderRadius: "20px",
            fontSize: "30px",
            width: "571px",
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "100px",
        }}
      >
        {/*mensaje*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "48px",
          }}
        >
       
          {repeatedContent()}
          {repeatedContent()}
        </Box>

        {/*BOTON*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          {/*boton texto*/}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="540px"
              height="88px"
              sx={{ borderRadius: "44px" }}
            />
          </Box>
          {/*Medio de pago*/}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "60px",
            }}
          >
            {/*tEXTO */}
            <Box
              sx={{
                display: "flex",
                width: "200px",
                flexDirection: "column",
                justifyContent: "center",
                alignSelf: "stretch",
              }}
            >
              <Skeleton
                variant="text"
                sx={{
                  borderRadius: "12px",
                  fontSize: "20px",
                  width: "200px",
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  borderRadius: "12px",
                  fontSize: "20px",
                  width: "100px",
                }}
              />
            </Box>
            {/*LOGOS*/}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "var(--radio-md, 20px)",
                alignSelf: "stretch",
              }}
            >
              <Skeleton
                variant="rectangular"
                sx={{
                  borderRadius: "12px",
                  width: "48px",
                  height: "48px",
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  borderRadius: "12px",
                  width: "48px",
                  height: "48px",
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  borderRadius: "12px",
                  width: "48px",
                  height: "48px",
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  borderRadius: "12px",
                  width: "48px",
                  height: "48px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SkeletonWelcome;
