import React, { useContext, useState } from "react";
import { Box, Button, Typography, keyframes } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { themes } from "../config/Icons";
import { UserContext } from "../context/UserProvider";

interface Props {
  isDarkMode: boolean;
  ThemesColors: any;
}
const WelcomeLanding = ({ isDarkMode, ThemesColors }: Props) => {
  //Modo OSCURO Y CLAR
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const navigate = useNavigate();
  const handleRightButtonClick = () => {
    navigate("/nameform"); // Redirige al usuario
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        maxHeight: "100vh",
        padding: "80px",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/*Texto*/}
      <Box>
        <Typography
          sx={{
            //color: "var(--color-full-800-primario, #42C67E)",
            color: ThemesColors.activo,
            textAlign: "center",
            fontFamily: "Gotham",
            fontSize: "72px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "120%",
          }}
        >
          ¡Bienvenido!
        </Typography>
        <Typography
          sx={{
            color: "var(--color-neutral-texto, #1E2947)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "36px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "120%",
          }}
        >
          Ordena y paga en un solo lugar
        </Typography>
      </Box>
      {/*PASOS */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "48px",
        }}
      >
        {/*ELIGE*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            //src={themeMode.icons.EligeActivo}
            style={{
              width: "140px",
              height: "140px",
              WebkitMaskImage: `url(${themeMode.icons.EligeActivo})`,
              WebkitMaskRepeat: "no-repeat",
              WebkitMaskSize: "140px",
              backgroundColor: ThemesColors.activo,
            }}
          />
          <Typography
            sx={{
              color: "var(--color-neutral-texto, #1E2947)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Elige
          </Typography>
        </Box>
        {/*fecha*/}
        <img
          src={themeMode.icons.flechaDerechaPasiva}
          style={{
            width: "72px",
            height: "72px",
          }}
        />
        {/*PAGA*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            //src={themeMode.icons.PagoActivo}
            style={{
              width: "140px",
              height: "140px",
              WebkitMaskImage: `url(${themeMode.icons.PagoActivo})`,
              WebkitMaskRepeat: "no-repeat",
              WebkitMaskSize: "140px",
              backgroundColor: ThemesColors.activo,
            }}
          />
          <Typography
            sx={{
              color: "var(--color-neutral-texto, #1E2947)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Paga
          </Typography>
        </Box>
        {/*fecha*/}
        <img
          src={themeMode.icons.flechaDerechaPasiva}
          style={{
            width: "72px",
            height: "72px",
          }}
        />
        {/*RECOGE*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            //  src={themeMode.icons.RecogeActivo}
            style={{
              width: "140px",
              height: "140px",
              WebkitMaskImage: `url(${themeMode.icons.RecogeActivo})`,
              WebkitMaskRepeat: "no-repeat",
              WebkitMaskSize: "140px",
              backgroundColor: ThemesColors.activo,
            }}
          />
          <Typography
            sx={{
              color: "var(--color-neutral-texto, #1E2947)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Recoge
          </Typography>
        </Box>
      </Box>
      {/*PANEL BOTON */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "28px",
        }}
      >
        {/*BOTON TEXTO*/}
        <Button
          onClick={handleRightButtonClick}
          sx={{
            width: "540px",
            height: "88px",
            color: "var(--neutralblanco, #FFF)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "120%",
            fontSize: "32px",
            textTransform: "revert-layer",
            borderRadius: "44px",
            background: ThemesColors.activo,
            boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
            marginTop: "12px",
            ":hover": {
              borderRadius: "44px",
              background: ThemesColors.activo,
              boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
            },
          }}
        >
          Toca para empezar
        </Button>
        {/*Medios de pago*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "60px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "200px",
              flexDirection: "column",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
          >
            <Typography
              sx={{
                color: "var(--color-neutral-900, #717883)",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "28px",
              }}
            >
              Puedes pagar con:
            </Typography>
          </Box>
          {/*lOGOS */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "var(--radio-md, 20px)",
              alignSelf: "stretch",
            }}
          >
            <img
              src={themeMode.logo.imgVisa}
              style={{
                width: "48px",
                height: "48px",
                fill: "var(--NEUTRAL-BLANCO, #FFF)",
                strokeWidth: "1px",
                stroke: "var(--color-neutral-300, #F2F2F2)",
                filter: "drop-shadow(0px 2px 10px rgba(30, 41, 71, 0.14))",
              }}
            />
            <img
              src={themeMode.logo.imgMastercad}
              style={{
                width: "48px",
                height: "48px",
                fill: "var(--NEUTRAL-BLANCO, #FFF)",
                strokeWidth: "1px",
                stroke: "var(--color-neutral-300, #F2F2F2)",
                filter: "drop-shadow(0px 2px 10px rgba(30, 41, 71, 0.14))",
              }}
            />
            <img
              src={themeMode.logo.imgAmericaExpress}
              style={{
                width: "48px",
                height: "48px",
                fill: "var(--NEUTRAL-BLANCO, #FFF)",
                strokeWidth: "1px",
                stroke: "var(--color-neutral-300, #F2F2F2)",
                filter: "drop-shadow(0px 2px 10px rgba(30, 41, 71, 0.14))",
              }}
            />
            <img
              src={themeMode.logo.imgDinersClub}
              style={{
                width: "48px",
                height: "48px",
                fill: "var(--NEUTRAL-BLANCO, #FFF)",
                strokeWidth: "1px",
                stroke: "var(--color-neutral-300, #F2F2F2)",
                filter: "drop-shadow(0px 2px 10px rgba(30, 41, 71, 0.14))",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WelcomeLanding;
