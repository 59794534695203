import React, { useContext, useState } from "react";
import { Box, Button, Typography, keyframes } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { themes } from "../config/Icons";
import { UserContext } from "../context/UserProvider";
import { WebCashRegister } from "../models/orders";

interface Props {
  isDarkMode: boolean;
  ThemesColors: any;
}
const WelcomeLandingCustom = ({ isDarkMode, ThemesColors }: Props) => {
  //Modo OSCURO Y CLAR
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const [webCashRegister, setWebCashRegister] = useState<WebCashRegister>(
    JSON.parse(localStorage.getItem("webCashRegister") || "")
  );
  const [imgPath, setImgPath] = useState<string>(
    localStorage.getItem("imgPath") || ""
  );
  
  const navigate = useNavigate();
  const handleRightButtonClick = () => {
    navigate("/nameform"); // Redirige al usuario
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        maxHeight: "100vh",
        padding: "80px 274px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "100px",
        background: "#FFF",
      }}
    >
      {/*mensaje*/}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/*TEXTO */}
        <Box
          sx={{
            display: "flex",
            width: "580px",
            padding: "0px 40px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--radio-sm, 12px)",
          }}
        >
          <Typography
            sx={{
              color: ThemesColors.activo,
              textAlign: "center",
              fontFamily: "Gotham",
              fontSize: "72px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "120%",
            }}
          >
            {webCashRegister.welcomeCustomizeTitle === null ||
            webCashRegister.welcomeCustomizeTitle === "" ? (
              <>¡Ordena aquí!</>
            ) : (
              webCashRegister.welcomeCustomizeTitle
            )}
          </Typography>
          <Typography
            sx={{
              color: "var(--color-neutral-texto, #1E2947) ",
              fontFamily: "Poppins",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "48px",
            }}
          >
            {webCashRegister.welcomeCustomizeTitle === null ||
            webCashRegister.welcomeCustomizeDescription === "" ? (
              <>Lo mejor de nuestra carta en un solo lugar</>
            ) : (
              webCashRegister.welcomeCustomizeDescription
            )}
          </Typography>
        </Box>
        {/*lOGO */}
        <img
          src={imgPath == "" ? themeMode.logo.imgQiosco : imgPath}
          style={{
            width: "240px",
            height: "240px",
          }}
        />
      </Box>

      {/*PANEL BOTON */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "40px",
        }}
      >
        {/*BOTON TEXTO*/}
        <Button
          onClick={handleRightButtonClick}
          sx={{
            width: "720px",
            height: "88px",
            color: "var(--neutralblanco, #FFF)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "120%",
            fontSize: "32px",
            textTransform: "revert-layer",
            borderRadius: "44px",
            // background: "var(--colorPrimary-Green)",
            background: ThemesColors.activo,
            boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
            marginTop: "12px",
            ":hover": {
              borderRadius: "44px",
              background: ThemesColors.activo,
              // background: "var(--colorSecondary-Green)",
              boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
            },
          }}
        >
          ¡Toca aquí para empezar!
        </Button>
        {/*Medios de pago*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "60px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "200px",
              flexDirection: "column",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
          >
            <Typography
              sx={{
                color: "var(--color-neutral-900, #717883)",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "28px",
              }}
            >
              Puedes pagar con:
            </Typography>
          </Box>
          {/*lOGOS */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "var(--radio-md, 20px)",
              alignSelf: "stretch",
            }}
          >
            <img
              src={themeMode.logo.imgVisa}
              style={{
                width: "48px",
                height: "48px",
                fill: "var(--NEUTRAL-BLANCO, #FFF)",
                strokeWidth: "1px",
                stroke: "var(--color-neutral-300, #F2F2F2)",
                filter: "drop-shadow(0px 2px 10px rgba(30, 41, 71, 0.14))",
              }}
            />
            <img
              src={themeMode.logo.imgMastercad}
              style={{
                width: "48px",
                height: "48px",
                fill: "var(--NEUTRAL-BLANCO, #FFF)",
                strokeWidth: "1px",
                stroke: "var(--color-neutral-300, #F2F2F2)",
                filter: "drop-shadow(0px 2px 10px rgba(30, 41, 71, 0.14))",
              }}
            />
            <img
              src={themeMode.logo.imgAmericaExpress}
              style={{
                width: "48px",
                height: "48px",
                fill: "var(--NEUTRAL-BLANCO, #FFF)",
                strokeWidth: "1px",
                stroke: "var(--color-neutral-300, #F2F2F2)",
                filter: "drop-shadow(0px 2px 10px rgba(30, 41, 71, 0.14))",
              }}
            />
            <img
              src={themeMode.logo.imgDinersClub}
              style={{
                width: "48px",
                height: "48px",
                fill: "var(--NEUTRAL-BLANCO, #FFF)",
                strokeWidth: "1px",
                stroke: "var(--color-neutral-300, #F2F2F2)",
                filter: "drop-shadow(0px 2px 10px rgba(30, 41, 71, 0.14))",
              }}
            />
          </Box>
        </Box>
      </Box>      
    </Box>
  );
};

export default WelcomeLandingCustom;
