import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { themes } from "../../../config/Icons";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import colorizeLottie from "../../../config/colorizeLottie";
import animationData from "../../../assets/lottie/SONRISA-VERDE_400X400.json";
import Lottie from "lottie-react";

interface Props {
  isDarkMode: boolean;
  setDirection: (direction: string | null) => void;
  ThemesColors: any;
}
const NameFromSection = ({ isDarkMode, setDirection, ThemesColors }: Props) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const { idRuta } = useContext(UserContext);
  const [name, setName] = useState(localStorage.getItem("userName") || "");
  const [focused, setFocused] = useState(false);
  const navigate = useNavigate();
  const nameInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    idRuta(location.pathname);
    localStorage.setItem("userName", name);
  }, [name]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleLeftButtonClick = () => {
    navigate("/welcome");
    setDirection("backward");
  };

  const handleRightButtonClick = (type: any) => {
    if (type == 1) {
      localStorage.removeItem("userName");
    }

    console.log("Nombre guardado:", name);
    navigate("/selection");
  };

  // METODO PARA CAMBIAR DE COLOR A LOS LOTTIE
  const colorizedSource = useMemo(
    () =>
      colorizeLottie(animationData, {
        "layers.5.shapes.0.it.1.c.k": ThemesColors.activo,
      }),
    []
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "var(--radio-md, 20px)",
      }}
    >
      {/* CABERA */}
      <Box
        sx={{
          display: "flex",
          width: "680px",
          alignItems: "center",
        }}
      >
        {/* TEXTO */}
        <Box
          sx={{
            display: "flex",
            padding: "0px 32px",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: "1 0 0",
          }}
        >
          <Typography
            sx={{
              color: ThemesColors.activo,
              fontFamily: "Gotham",
              fontSize: "68px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "120%" /* 81.6px */,
            }}
          >
            ¡Hola!
          </Typography>
          <Typography
            sx={{
              color: "var(--texto, #1E2947)",
              fontFamily: "Poppins",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "120%",
            }}
          >
            ¿Cuál es tu nombre?
          </Typography>
        </Box>
        {/* IMAGEN LOTTIE */}
        <Lottie
          animationData={colorizedSource}
          loop={true}
          autoplay={true}
          style={{
            height: 240,
            width: 240,
          }}
        />
      </Box>
      {/* BOTON ICONO IZQUIERDA */}
      <Box
        onClick={handleLeftButtonClick}
        sx={{
          display: "flex",
          width: "60px",
          height: "60px",
          padding: "15px",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "20px",
          top: "20px",
          borderRadius: "24px",
          background: ThemesColors.activo,
          boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
          ":hover": {
            borderRadius: "24px",
            background: ThemesColors.activo,
            boxShadow: "3px 2px 10px 3px rgba(30, 41, 71, 0.14)",
          },
        }}
      >
        <img
          src={themeMode.icons.flechaIzquierdaPasiva}
          style={{
            width: "30px",
            height: "30px",
            flexShrink: 0,
          }}
        />
      </Box>
      {/* NOMBRE */}
      <Box
        sx={{
          display: "flex",
          width: "680px",
          padding: "0px 36px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "60px",
        }}
      >
        {/* campo */}
        <Box
          sx={{
            display: "flex",
            height: "88px",
            padding: "10px 40px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            //borderBottom: "1px solid var(--color-full-800-primario, #42C67E)",
            borderBottom: `1px solid var(--color-full-800-primario, ${ThemesColors.activo})`,
            color: "var(--color-neutral-800, #88919E)",
          }}
        >
          <TextField
            label="Escribe tu nombre"
            value={name}
            onChange={handleNameChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            fullWidth
            variant="outlined"
            inputRef={nameInputRef}
            InputProps={{
              style: {
                padding: "0", // Eliminar el padding para centrar mejor el texto
                fontFamily: "Poppins",
                fontSize: "44px",
                fontWeight: 400,
                lineHeight: "120%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              inputProps: {
                style: {
                  textAlign: "center", // Centrar el texto ingresado
                },
              },
            }}
            InputLabelProps={{
              style: {
                fontFamily: "Poppins",
                fontSize: "44px",
                fontWeight: 400,
                lineHeight: "120%",
                color: "var(--color-neutral-800, #88919E)",
                textAlign: "center",
                width: "100%", // Hacer que el label ocupe todo el ancho
                visibility: name || focused ? "hidden" : "visible", // Ocultar el label cuando hay texto o está enfocado
              },
              shrink: false, // Evitar que el label se mueva al escribir
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remover borde del TextField
              },
              "& .MuiInputLabel-root": {
                transition: "visibility 0.1s ease-in-out", // Añadir transición suave
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "var(--radio-sm, 12px)",
            alignSelf: "stretch",
            marginTop: "20px", // Ajustar según sea necesario
          }}
        ></Box>
        {/* BOTONES PRINCIPALES */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "var(--radio-sm, 12px)",
            alignSelf: "stretch",
          }}
        >
          <Button
            onClick={handleRightButtonClick.bind(null, 1)}
            sx={{
              display: "flex",
              color: ThemesColors.activo,
              height: "80px",
              padding: "12px 40px",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              flex: "1 0 0",
              borderRadius: "40px",
              border: `1px solid var(--color-full-800-primario, ${ThemesColors.activo})`,
              ":hover": {
                borderRadius: "40px",
                background: ThemesColors.activo,
                boxShadow: "3px 2px 10px 3px rgba(30, 41, 71, 0.14)",
                color: "var(--neutralblanco, #FFF)",
              },
              textAlign: "center",
              fontFamily: "Gotham",
              textTransform: "revert-layer",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "28px",
            }}
          >
            Omitir
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!name.trim()}
            onClick={handleRightButtonClick.bind(null, 2)}
            sx={{
              display: "flex",
              height: "80px",
              padding: "12px 40px",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              flex: "1 0 0",
              borderRadius: "40px",
              color: "var(--neutralblanco, #FFF)",
              background: ThemesColors.activo,
              boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
              ":hover": {
                borderRadius: "40px",
                background: ThemesColors.activo,
                boxShadow: "3px 2px 10px 3px rgba(30, 41, 71, 0.14)",
                color: "var(--neutralblanco, #FFF)",
              },
              textAlign: "center",
              fontFamily: "Gotham",
              textTransform: "revert-layer",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "28px",
            }}
          >
            Confirmar
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NameFromSection;
