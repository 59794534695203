import React, { KeyboardEvent, useContext, useMemo, useState } from "react";
import { Dialog, Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import ColorButtonStyle from "../ColorButtonStyle";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import animationData from "../../assets/lottie/ALERTA_320X320.json";
import colorizeLottie from "../../config/colorizeLottie";
import TextFieldLogin from "../TextField/TextFieldLogin";
import { UserContext } from "../../context/UserProvider";
import { useNavigate } from "react-router-dom";
import { Login } from "../../services/storage.service";
import { LoginObject } from "../../models/authUser";
import { getStoreByUserId } from "../../services/localServices";
import { getUsers } from "../../services/users.service";
import LoaderScreen from "../../pages/LoaderScreenLogin";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleClose: () => void;
  themeMode?: any;
  setIsLoadingScreen: Function;
}

const ModalCerrarSesion: React.FC<Props> = ({
  open,
  handleClose,
  themeMode,
  setIsLoadingScreen,
}) => {
  const { idTokenUser, idRuta, idStore, idUserData } = useContext(UserContext);
  const [activeFocusContraseña, setActiveFocusContraseña] = useState(false);
  const [errorContraseña, setErrorContraseña] = useState(false);
  const [msjErrorContraseña, setMsjErrorContraseña] = useState("");
  const [contraseña, setContraseña] = useState("");
  const [lengthContraseña, setLengthContraseña] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true); // desabilitar boton

  const handleFocusContraseña = (event: React.FocusEvent<HTMLElement>) => {
    setActiveFocusContraseña(true);
    setMsjErrorContraseña("");
  };

  const handleBlurContraseña = (event: React.FocusEvent<HTMLElement>) => {
    setErrorContraseña(false);
    setActiveFocusContraseña(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "nContraseña") {
      setErrorContraseña(false);
      setContraseña(value);
      setLengthContraseña(value.length);
      setIsDisabled(value.trim().length === 0);
      
    }
  };

  const fetchLogout = async () => {
    try {
      setIsDisabled(true);
      if (contraseña === "") {
        setErrorContraseña(true);
        setMsjErrorContraseña(" Contraseña Incorrecta");
        setIsLoadingScreen(false); // Ocultar la pantalla de carga
        return;
      }
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const login = { Username: userData.email, Password: contraseña };
      const sessionToken = await Login(login);
      setIsLoadingScreen(false);
      if (sessionToken?.status === 200 || sessionToken?.status === 201) {
        // Mostrar la pantalla de carga inmediatamente
        setIsDisabled(false);
        setIsLoadingScreen(true);
        setTimeout(() => {
          localStorage.clear();
          idRuta(undefined);
          idTokenUser(undefined);
          window.location.reload(); // Recargar la página
        }, 2000); // Esperar 2 segundos
      } else {
        setErrorContraseña(true);
        setMsjErrorContraseña(" Revise sus credenciales");
        setIsLoadingScreen(false);
        setIsDisabled(false);
      }
    } catch (error: any) {
      console.log("fetchLogout Error:", error.message);
      setIsLoadingScreen(false);
    }
  };

  // METODO PARA CAMBIAR DE COLOR A LOS LOTTIE
  const colorizedSource = useMemo(
    () =>
      colorizeLottie(animationData, {
        // Cambia estos paths y colores según sea necesario
        "layers.0.shapes.0.it.1.c.k": "#F44336",

        "layers.1.shapes.0.it.2.c.k": "#F44336",

        "layers.2.shapes.0.it.2.c.k": "#F44336",
      }),
    []
  );

  return (
    <>
      {" "}
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        maxWidth="xl"
        disableScrollLock={true}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            display: "flex",
            outline: "none",
            width: "440px",
            padding: "24px",
            background: "#FFF",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
            borderRadius: "20px",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
            zIndex: 1300,
          },
        }}
      >
        {/* Cabecera */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "28px",
            alignSelf: "stretch",
          }}
        >
          {/* Imagen Lottie */}
          <Lottie
            animationData={colorizedSource}
            loop={true}
            autoplay={true}
            style={{
              height: "120px",
              width: "120px",
            }}
          />
          {/* Texto */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              sx={{
                color: "#F44336",
                textAlign: "center",
                fontFamily: "Gotham",
                fontSize: "24px",
                fontWeight: 400,
                lineHeight: "120%",
              }}
            >
              ¿Deseas salir de la tienda?
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                lineHeight: "150%",
                alignSelf: "stretch",
                fontSize: "16px",
                textAlign: "center",
                fontStyle: "normal",
              }}
            >
              <span
                style={{
                  fontWeight: 300,
                  marginRight: "8px",
                }}
              >
                Ingresa tu contraseña
              </span>
              <span
                style={{
                  fontWeight: 500,
                }}
              >
                cerrar la sesión.
              </span>
            </Typography>
          </Box>
        </Box>
        {/*Campo de texto */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            alignSelf: "stretch",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
              alignSelf: "stretch",
            }}
          >
            <TextFieldLogin
              key={1}
              label="Ingresa tu contraseña"
              title=""
              titleInput=""
              nameValue={"nContraseña"}
              type={showPassword ? "text" : "password"}
              required={true}
              activeFocus={activeFocusContraseña}
              length={lengthContraseña}
              value={contraseña}
              showLogin={showPassword}
              imgPasivo={themeMode.icons.clavePasivo}
              imgActivo={themeMode.icons.claveActivo}
              activoOculto={themeMode.icons.activoOculto}
              activoVisible={themeMode.icons.activoVisible}
              mensajeError={msjErrorContraseña}
              error={errorContraseña}
              handleClickShowLogin={handleClickShowPassword}
              handleMouseDownLogin={handleMouseDownPassword}
              handleFocusLogin={handleFocusContraseña}
              handleBlurLogin={handleBlurContraseña}
              handleChangeInputValues={handleChangeInput}
              bgColor={"#F44336"}
              hoverColor={"#FF8279"}
            />
          </Box>
        </Box>

        {/* Botones */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "var(--radio-sm, 12px)",
            alignSelf: "stretch",
          }}
        >
          <ColorButtonStyle
            handleOpen={handleClose}
            title="cancelar"
            active={false}
            color="#F44336"
            hovercolor="#FF655A"
          />
          <ColorButtonStyle
            handleOpen={fetchLogout}
            title="sí, cerrar"
            active={true}
            color="#F44336"
            hovercolor="#FF655A"
            disabled={isDisabled}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default ModalCerrarSesion;
