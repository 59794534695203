import { Button } from "@mui/material";

interface Props {
  handleOpen: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  active: boolean;
  propina?: boolean;
  color?: string;
  hovercolor?: string;
  disabled?: boolean; // Nueva prop para deshabilitar el botón
}

const ColorButtonStyle: React.FC<Props> = ({
  handleOpen,
  title,
  active,
  propina,
  color,
  hovercolor,
  disabled = false, // Valor por defecto para disabled
}) => {

  return (
    <Button
      onClick={handleOpen}
      disabled={disabled}
      sx={{
        transition:"none",
        color: disabled ? "#F2F2F2" : active ? "white" : color,
        backgroundColor: disabled ? "#f0f0f0" : active ? color : "white",
        textTransform: "uppercase",
        height: "56px",
        width: "100%",
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "18px",
        textAlign: "center",
        fontFamily: "Poppins",
        gap: "10px",
        fontStyle: "normal",
        display: "-webkit-inline-box !important",
        borderRadius: "30px",
        border: disabled     ? "none"    : active  ? ""  : propina  ? "0px solid"   : `1px solid ${color}`, // Sin borde cuando está deshabilitado
        textDecorationLine: propina ? "underline" : "",
        cursor: disabled ? "not-allowed" : "pointer", // Cambia el cursor si está deshabilitado
        "&:hover": {
          backgroundColor: disabled ? "#f0f0f0" : propina ? "#fff" : hovercolor,
          textDecorationLine: propina ? "underline" : "",
          color: propina ? color : "white",
          border: propina || disabled ? "none" : `1px solid ${color}`, // Sin borde al hover si está deshabilitado
        },
      }} 
    >
      {title}
    </Button>
  );
};

export default ColorButtonStyle;
