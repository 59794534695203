// reducers.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const amountSlice = createSlice({
  name: "amount",
  initialState: 0,
  reducers: {
    reset: (state, action: PayloadAction<number>) => {
        return action.payload;
    }
  }
});

export default amountSlice.reducer;
