import {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import LoaderScreen from "../pages/LoaderScreen";
import ProtectedRoute from "../authenticate/protectedRoutes";
import PublicRoute from "../authenticate/publicRoutes";
import { UserContext, UserProvider } from "../context/UserProvider";
import { ThemeProvider, CssBaseline } from "@mui/material";
import getTheme from "../config/theme";
import WelcomeSection from "../pages/MainScreen/components/WelcomeSection";
import SelectionObsionSection from "../pages/MainScreen/components/SelectionObsionSection";
import NameFromSection from "../pages/MainScreen/components/NameFromSection";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { WebCashRegister } from "../models/orders";
import { themes } from "../config/Icons";
import { getProductos } from "../services/productos.service";



const CartScreen = lazy(() => import("../pages/CartScreen"));
const CheckoutScreen = lazy( () => import("../pages/CheckoutScreen/CheckoutScreen"));
const MainScreen = lazy(() => import("../pages/MainScreen/MainScreen"));

const AnimatedRoutes = () => {
  const [direction, setDirection] = useState<string | null>("forward");
  const [darkMode, setDarkMode] = useState(false);
  const location = useLocation();
  const handleThemeChange = () => { setDarkMode((prevMode) => !prevMode); };
  const previousPathnameRef = useRef(location.pathname);
  // UTILIZAR MODO CAMBIO DE COLOR
  const { ThemesColors } = useContext(UserContext); 
  
  useEffect(() => {
    if (location.pathname !== previousPathnameRef.current) {
      // setDirection(
      //   location.pathname > previousPathnameRef.current ? 'forward' : 'backward'
      // );
      setDirection("forward");
      previousPathnameRef.current = location.pathname;
    }
  }, [location.pathname]);  

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames={
          direction === "forward" ? "slide-forward" : "slide-backward"
        }
        timeout={2000}
      >
        <Routes location={location}>  
          <Route path="/welcome"  element={<WelcomeSection isDarkMode={darkMode} ThemesColors={ThemesColors}/>} />
          <Route  path="/nameform" element={ <NameFromSection isDarkMode={darkMode} setDirection={setDirection} ThemesColors={ThemesColors} />}  />
          <Route path="/selection"  element={ <SelectionObsionSection  isDarkMode={darkMode} setDirection={setDirection} ThemesColors={ThemesColors} />  }/>
          {/* <Route path="/listapedido"element={ <CartScreen isDarkMode={darkMode} handleThemeChange={handleThemeChange} /> } />  */}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

const Index = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useMemo(() => getTheme(darkMode), [darkMode]);  

  const handleThemeChange = () => {
    setDarkMode((prevMode) => !prevMode);
  };
  const dialogRef = useRef<HTMLDivElement>(null);
  //funcion RealFuncion Full Screnn

  const ToggleFullScreen = () => {
    const element = document.getElementById("Container");
    const isFullScreen = document.fullscreenElement;

    if (isFullScreen) {
      document.exitFullscreen();
      if (dialogRef.current) {
        dialogRef.current.style.width = "480px";
        dialogRef.current.style.height = "720px";
      }
    } else {
      element?.requestFullscreen();
      if (dialogRef.current) {
        dialogRef.current.style.width = "100%";
        dialogRef.current.style.height = "100%";
      }
    }
  };
  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <div id={"Container"}>
          <CssBaseline />
          <BrowserRouter>
            <UserProvider>
              <Routes>
                <Route
                  path="/"
                  element={
                    <PublicRoute>                        
                      <MainScreen isDarkMode={darkMode} />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/*"
                  element={
                    <ProtectedRoute>
                      <AnimatedRoutes />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/listapedido"
                  element={
                    <ProtectedRoute>
                      <CartScreen
                        isDarkMode={darkMode}
                        handleThemeChange={handleThemeChange}                        
                      />
                    </ProtectedRoute>
                  }
                />
                {/* <Route path="/checkout" element={ <ProtectedRoute> <CheckoutScreen setIsLoading={undefined} isLoading={undefined} /></ProtectedRoute> } />    */}
                {/* <Route path="/checkout" element={ <ProtectedRoute> <CheckoutScreen setIsLoading={undefined} isLoading={undefined} /></ProtectedRoute> } />    */}
              </Routes>
            </UserProvider>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </Suspense>
  );
};

export default Index;
