const lightIcons = {
  //ICONOS HOVER
  searchcerrarhover: `${process.env.PUBLIC_URL}/Icon/light/BUSCAR-CERRAR-HOVER_48X48.svg`,
  searchhover: `${process.env.PUBLIC_URL}/Icon/light/BUSCAR-HOVER_48X48.svg`,
  imgUsuarioActivo: `${process.env.PUBLIC_URL}/Icon/light/USUARIO-HOVER_36X36.svg`,
  bolsaHover: `${process.env.PUBLIC_URL}/Icon/light/BOLSA_HOVER_100X100.svg`,
  tiendaHover: `${process.env.PUBLIC_URL}/Icon/light/TIENDA_HOVER_100X100.svg`,

  //ICONOS PASIVO
  searchcerrarpasivo: `${process.env.PUBLIC_URL}/Icon/light/BUSCAR-CERRAR-PASIVO_48X48.svg`,
  searchpasivo: `${process.env.PUBLIC_URL}/Icon/light/BUSCAR-PASIVO_48X48.svg`,
  location: `${process.env.PUBLIC_URL}/Icon/light/LOCATION_PASIVO_20X20.svg`,
  UsuarioPasivo: `${process.env.PUBLIC_URL}/Icon/light/USUARIO-PASIVO_24X24.svg`,
  clavePasivo: `${process.env.PUBLIC_URL}/Icon/light/CONTRASENA_PASIVO_24X24.svg`,
  docPasivo: `${process.env.PUBLIC_URL}/Icon/light/DOC-PASIVO_24X24.svg`,
  rucPasivo: `${process.env.PUBLIC_URL}/Icon/light/RUC-PASIVO_24X24.svg`,
  socialPasivo: `${process.env.PUBLIC_URL}/Icon/light/SOCIAL-PASIVO_24X24.svg`,
  imgUsuarioPasivo: `${process.env.PUBLIC_URL}/Icon/light/USUARIO-PASIVO_36X36.svg`,
  BolsaPasivo: `${process.env.PUBLIC_URL}/Icon/light/BOLSA-PASIVO_24X24.svg`,
  salirPasivo: `${process.env.PUBLIC_URL}/Icon/light/SALIR-PASIVO_24X24.svg`,
  flechaDerechaPasiva: `${process.env.PUBLIC_URL}/Icon/light/FLECHA_DERECHA_PASIVO.svg`,
  flechaIzquierdaPasiva: `${process.env.PUBLIC_URL}/Icon/light/FLECHA_IZQUIERDA_PASIVA_24X24.svg`,
  imagenIconPasivo: `${process.env.PUBLIC_URL}/Icon/light/IMAGEN_PASIVO_24X24.svg`,
  flechaPasivo: `${process.env.PUBLIC_URL}/Icon/light/FLECHA_ABAJO_PASIVO_24X24.svg`,
  flechaNavDerecha: `${process.env.PUBLIC_URL}/Icon/light/FLECHA_DERECHA_WHILE_PASIVO_24X24.svg`,

  //ICONOS ACTIVO
  UsuarioActivo: `${process.env.PUBLIC_URL}/Icon/light/USUARIO-ACTIVO_24X24.svg`,
  claveActivo: `${process.env.PUBLIC_URL}/Icon/light/CONTRASENA_ACTIVO_24X24.svg`,
  activoVisible: `${process.env.PUBLIC_URL}/Icon/light/CONTRASENA_ACTIVO_VER_24X24.svg`,
  activoOculto: `${process.env.PUBLIC_URL}/Icon/light/CONTRASENA_ACTIVO_OCULTAR_24X24.svg`,
  BolsaActivo: `${process.env.PUBLIC_URL}/Icon/light/BOLSA-ACTIVO_24X24.svg`,
  SalirActivo: `${process.env.PUBLIC_URL}/Icon/light/SALIR-ACTIVO_24X24.svg`,
  EligeActivo: `${process.env.PUBLIC_URL}/Icon/light/ELIGE_ACTIVO_140X140.svg`,
  PagoActivo: `${process.env.PUBLIC_URL}/Icon/light/PAGO_ACTIVO_140X140.svg`,
  RecogeActivo: `${process.env.PUBLIC_URL}/Icon/light/RECOGE_ACTIVO_140X140.svg`,
  cerrarActivo: `${process.env.PUBLIC_URL}/Icon/light/CERRAR-ACTIVO_22X22.svg`,
  imagenIconActivo: `${process.env.PUBLIC_URL}/Icon/light/IMAGEN_ACTIVO_24X24.svg`,
  docActivo: `${process.env.PUBLIC_URL}/Icon/light/DOC-ACTIVO_24X24.svg`,
  rucActivo: `${process.env.PUBLIC_URL}/Icon/light/RUC-ACTIVO_24X24.svg`,
  socialActivo: `${process.env.PUBLIC_URL}/Icon/light/SOCIAL-ACTIVO_24X24.svg`,
  flechaActivo: `${process.env.PUBLIC_URL}/Icon/light/FLECHA_ABAJO_ACTIVO_24X24.svg`,
  salirActivo: `${process.env.PUBLIC_URL}/Icon/light/SALIR-ACTIVO_24X24.svg`,

  //ICONOS
  menos: `${process.env.PUBLIC_URL}/Icon/light/MENOS_CARRITO_24X24.svg`,
  masHover: `${process.env.PUBLIC_URL}/Icon/light/MAS_HOVER_40X40.svg`,
  masPasivo: `${process.env.PUBLIC_URL}/Icon/light/MAS_PASIVO_40X40.svg`,
  checkActivo: `${process.env.PUBLIC_URL}/Icon/light/CHECK_ACTIVO_40X40.svg`,
  mas: `${process.env.PUBLIC_URL}/Icon/light/MAS_CARITO_24X24.svg`,

  editarActivo: `${process.env.PUBLIC_URL}/Icon/light/EDITAR_PROPINA_ACTIVO_24X24.svg`,
  editarPasivo: `${process.env.PUBLIC_URL}/Icon/light/EDITAR_PROPINA_PASIVO_24X24.svg`,

  eliminarActivo: `${process.env.PUBLIC_URL}/Icon/light/ELIMINAR_PROPINA_ACTIVO_24X24.svg`,
  eliminarPasivo: `${process.env.PUBLIC_URL}/Icon/light/ELIMINAR_PROPINA_PASIVO_24X24.svg`,


};

const darkIcons = {
  //ICONOS HOVER
  searchcerrarhover: `${process.env.PUBLIC_URL}/Icon/Dark/BUSCAR-CERRAR-HOVER_48X48.svg`,
  searchhover: `${process.env.PUBLIC_URL}/Icon/Dark/BUSCAR-HOVER_48X48.svg`,
  imgUsuarioActivo: `${process.env.PUBLIC_URL}/Icon/Dark/USUARIO-HOVER_36X36.svg`,
  bolsaHover: `${process.env.PUBLIC_URL}/Icon/Dark/BOLSA_HOVER_100X100.svg`,
  tiendaHover: `${process.env.PUBLIC_URL}/Icon/Dark/TIENDA_HOVER_100X100.svg`,

  //ICONOS PASIVO
  searchcerrarpasivo: `${process.env.PUBLIC_URL}/Icon/Dark/BUSCAR-CERRAR-PASIVO_48X48.svg`,
  searchpasivo: `${process.env.PUBLIC_URL}/Icon/Dark/BUSCAR-PASIVO_48X48.svg`,
  location: `${process.env.PUBLIC_URL}/Icon/Dark/LOCATION_PASIVO_20X20.svg`,
  UsuarioPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/USUARIO-PASIVO_24X24.svg`,
  clavePasivo: `${process.env.PUBLIC_URL}/Icon/Dark/CONTRASENA_PASIVO_24X24.svg`,
  imgUsuarioPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/USUARIO-PASIVO_36X36.svg`,
  BolsaPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/BOLSA-PASIVO_24X24.svg`,
  salirPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/SALIR-PASIVO_24X24.svg`,
  flechaDerechaPasiva: `${process.env.PUBLIC_URL}/Icon/Dark/FLECHA_DERECHA_PASIVO.svg`,
  imagenIconPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/IMAGEN_PASIVO_24X24.svg`,
  docPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/DOC-PASIVO_24X24.svg`,
  rucPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/RUC-PASIVO_24X24.svg`,
  socialPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/SOCIAL-PASIVO_24X24.svg`,
  docActivo: `${process.env.PUBLIC_URL}/Icon/Dark/DOC-ACTIVO_24X24.svg`,
  rucActivo: `${process.env.PUBLIC_URL}/Icon/Dark/RUC-ACTIVO_24X24.svg`,
  socialActivo: `${process.env.PUBLIC_URL}/Icon/Dark/SOCIAL-ACTIVO_24X24.svg`,


  //ICONOS
  menos: `${process.env.PUBLIC_URL}/Icon/Dark/MENOS_CARRITO_24X24.svg`,
  masHover: `${process.env.PUBLIC_URL}/Icon/Dark/MAS_HOVER_40X40.svg`,
  masPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/MAS_PASIVO_40X40.svg`,
  checkActivo: `${process.env.PUBLIC_URL}/Icon/Dark/CHECK_ACTIVO_40X40.svg`,
  mas: `${process.env.PUBLIC_URL}/Icon/Dark/MAS_CARITO_24X24.svg`,


  //ICONOS ACTIVO
  UsuarioActivo: `${process.env.PUBLIC_URL}/Icon/Dark/USUARIO-ACTIVO_24X24.svg`,
  claveActivo: `${process.env.PUBLIC_URL}/Icon/Dark/CONTRASENA_ACTIVO_24X24.svg`,
  activoVisible: `${process.env.PUBLIC_URL}/Icon/Dark/CONTRASENA_ACTIVO_VER_24X24.svg`,
  activoOculto: `${process.env.PUBLIC_URL}/Icon/Dark/CONTRASENA_ACTIVO_OCULTAR_24X24.svg`,
  BolsaActivo: `${process.env.PUBLIC_URL}/Icon/Dark/BOLSA-ACTIVO_24X24.svg`,
  SalirActivo: `${process.env.PUBLIC_URL}/Icon/Dark/SALIR-ACTIVO_24X24.svg`,

  EligeActivo: `${process.env.PUBLIC_URL}/Icon/Dark/ELIGE_ACTIVO_140X140.svg`,
  PagoActivo: `${process.env.PUBLIC_URL}/Icon/Dark/PAGO_ACTIVO_140X140.svg`,
  RecogeActivo: `${process.env.PUBLIC_URL}/Icon/Dark/RECOGE_ACTIVO_140X140.svg`,
  flechaIzquierdaPasiva: `${process.env.PUBLIC_URL}/Icon/Dark/FLECHA_IZQUIERDA_PASIVA_24X24.svg`,
  cerrarActivo: `${process.env.PUBLIC_URL}/Icon/Dark/CERRAR-ACTIVO_22X22.svg`,

  imagenIconActivo: `${process.env.PUBLIC_URL}/Icon/Dark/IMAGEN_ACTIVO_24X24.svg`,
  salirActivo: `${process.env.PUBLIC_URL}/Icon/light/SALIR-ACTIVO_24X24.svg`,

};

const LogoIcons = {
  imgQiosco: `${process.env.PUBLIC_URL}/Icon/logo/LOGO_QIOSCO_512x512.svg`,
  imgCajaWeb: `${process.env.PUBLIC_URL}/Icon/logo/LOGO-CAJA-WEB_180X180.svg`,
  imgDinersClub: `${process.env.PUBLIC_URL}/Icon/logo/DINERS_CLUB_48X48.svg`,
  imgAmericaExpress: `${process.env.PUBLIC_URL}/Icon/logo/AMERICAN_EXPRESS_48X48.svg`,
  imgMastercad: `${process.env.PUBLIC_URL}/Icon/logo/MASTERCARD_48X48.svg`,
  imgVisa: `${process.env.PUBLIC_URL}/Icon/logo/VISA_48X48.svg`,
  imgPedido: `${process.env.PUBLIC_URL}/Icon/logo/LOGO_INICIO_PEDIDO_240x240.svg`,
};
const ImagenGif = {
  smileFace: `${process.env.PUBLIC_URL}/Icon/GIF/SMILE_IMAGEN.gif`,
  PinPos: `${process.env.PUBLIC_URL}/Icon/GIF/PINPAD_POS.gif`,
  circuloProgress: `${process.env.PUBLIC_URL}/Icon/GIF/CARGAR_VERDER_208X280.gif`,
}

const lightColors = {
  pasivoTrasp: "rgba(255, 255, 255, 0.80)",
  activoTrasp: "rgba(66, 198, 126, 0.92)",

  pasivo: "#30ADD2",
  // textModalAler: "#30ADD2",
  hover: "#79DD8B",
  fondoContainer: "#FFF",
  activo: "#42C67E",

  hoverRojo: "#FF655A"
};

const darkColors = {
  pasivoTrasp: "rgba(255, 255, 255, 0.80)",
  activoTrasp: "rgba(66, 198, 126, 0.92)",

  pasivo: "#30ADD2",
  hover: "#79DD8B",
  activo: "#42C67E",
  fondoContainer: "#FFF",
  hoverRojo: "#FF655A"

};


export const themes = {
  light: {
    icons: lightIcons,
    colors: lightColors,
    logo: LogoIcons,
    Gif: ImagenGif,

  },
  dark: {
    icons: darkIcons,
    colors: darkColors,
    logo: LogoIcons,
    Gif: ImagenGif
  },

};
